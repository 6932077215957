
export enum PenguinPart {
    Eyes = "Eyes",
    Head = "Head",
    InnerBody = "InnerBody",
    OuterBody = "OuterBody",
    Beak = "Beak",
    Feet = "Feet"
}


export enum PenguinRarity {
    Common = "Common",
    Uncommon = "Uncommon",
    KindaRare = "KindaRare",
    RareRare= "RareRare",
    Rare4Realz = "Rare4Realz"
}

export interface PenguinAttribute {
    attributeName: string;
    attributeImage: string;
    penguinPart: PenguinPart;
    rarity: PenguinRarity;
}

import Bowtie from './inner-body/bowtie1_3.png'
import FancyBowtie from './inner-body/bowtie2_1.png'
import ChainNecklace from './inner-body/chain_necklace.png'
import ChineseCollarShirt from './inner-body/chinese_collar_shirt_2.png'
import CookCloath from './inner-body/cook_cloath_1.png'
import GentlemanVest from './inner-body/gentleman_vest_2.png'
import GirlsNecklace from './inner-body/girls_necklace_1.png'
import GoldenBody from './inner-body/golden_body_1.png'
import HawaiinShirt from './inner-body/hawain_shirt_1.png'
import JeanVest from './inner-body/jean_vest.png'
import OfficeShirt from './inner-body/office_t_shirt_2.png'
import GraduationScarf from './inner-body/raduation_scarf_1.png'
import BlueTShirt from './inner-body/t_shirts_4.png'
import HeartTShirt from './inner-body/t_shirts_2.png'
import WorldTShirt from './inner-body/t_shirts_1.png'
import SkullTShirt from './inner-body/t_shirts_3.png'
import NeckTie from './inner-body/tie_5.png'
import WinterScarf from './inner-body/winter_scarf_1.png'
import DevilsTail from './outer-body/devlis_tail.png'
import Fur from './outer-body/fur_1.png'
import GoldenOuterBody from './outer-body/gold_2.png'
import LeopardPrint from './outer-body/leopard_print.png'
import PirateHook from './outer-body/pirate_hook.png'
import RainbowBody from './outer-body/rainbow.png'
import SuperheroCloak from './outer-body/superhero_cloak.png'
import VikingBracelet from './outer-body/viking_braclet.png'
import WinterGloves from './outer-body/winter_gloves.png'
import WizardsCloak from './outer-body/wizards_cloak.png'
import ZombieBites from './outer-body/zombie_bites.png'
import DatHat from './head/added_hat.png'
import Beanie from './head/beanie.png'
import CookHat from './head/cook_hat.png'
import CowboyHat from './head/cowboy_hat.png'
import GentlemanHat from './head/gentleman_hat.png'
import SummerHat from './head/girls_hat.png'
import GraduationCap from './head/graduation_cap.png'
import SherlockCap from './head/herlock_irish_hat.png'
import Horns from './head/horns.png'
import OldMagicianHat from './head/old_magician_hat.png'
import OldSchoolChineseHat from './head/old_school_chinese_hat.png'
import PirateHat from './head/pirate_hat.png'
import PoliceHat from './head/police_hat.png'
import RegularHat from './head/regular_hat.png'
import SailorHat from './head/sailor_hat.png'
import SantaHat from './head/santa_hat.png'
import Tiara from './head/tiara.png'
import VikingsHelmet from './head/vikings_helmet.png'
import WarriorHelmet from './head/warrior_helmet.png'
import WizardHat from './head/wizard_hat.png'
import AngryEyes from './eyes/angry.png'
import HappyEyes from './eyes/happy_2.png'
import SadEyes from './eyes/sad.png'
import SurprisedEyes from './eyes/surprised.png'
import SuspiciousEyes from './eyes/suspicious.png'
import WinkEyes from './eyes/wink.png' 
import NerdyGlasses from './eyes/big nerdy glasses/angry.png'
import DaftPunk from './eyes/duft punk/angry.png'
import EyePatch from './eyes/eye patch/angry.png'
import Eyelashes from './eyes/eyelashes/happy_2.png'
import ScarEyes from './eyes/harry potter scar/happy.png'
import EyeMasks from './eyes/mask/wink.png'
import ModernSunglasses from './eyes/modern sunglasses/suspicious.png'
import NarrowGlasses from './eyes/narrow glasses/surprised.png'
import OneEyeGlass from './eyes/one eye glass/angry.png' 
import RoundSunglasses from './eyes/round sunglasses/sad.png'
import SteamPunk from './eyes/steampunk/angry.png'
import Sunglasses from './eyes/sunglasse/surprised.png'
import OuterBodyRed from './outer-body/different_colours_1.png'
import OuterBodyGreen from './outer-body/different_colours_2.png'
import OuterBodyOrange from './outer-body/different_colours_3.png'
import OuterBodyBlue from './outer-body/different_colours_4.png'
import OuterBodyGrey from './outer-body/different_colours_5.png'
import OuterBodyPurple from './outer-body/different_colours_6.png'
import OuterBodyPink from './outer-body/different_colours_7.png'
import RedFeet from './feet/different_colours_1.png'
import GreenFeet from './feet/different_colours_2.png'
import OrangeFeet from './feet/different_colours_3.png'
import BlueFeet from './feet/different_colours_4.png'
import GreyFeet from './feet/different_colours_5.png'
import PurpleFeet from './feet/different_colours_6.png'
import PinkFeet from './feet/different_colours_7.png'
import YellowFeet from './feet/different_colours_8.png'
import GoldenFeet from './feet/golden_feet_1.png'
import Boots from './feet/boots_2.png'
import ConverseShoes from './feet/converse_shoes_3.png'
import FormalShoes from './feet/formal_shoes_2.png'
import GirlyAnklet from './feet/girly_anklet_1.png'
import OldLeatherBoots from './feet/old_leather_boots_3.png'
import PirateWoodLeg from './feet/pirate_wood_leg.png'
import RunningShoes from './feet/runner_shoes_2.png'
import SchoolShoes from './feet/school_shoes_2.png'
import SummerSlippers from './feet/summer_slippers_1.png'
import Vans from './feet/vans_1.png'
import NormalBeak from './beak/penguin_beak.png'
import HappyBeak from './beak/different_colours_1_1.png'
import SurprisedBeak from './beak/different_colours_2_2.png'
import ConfusedBeak from './beak/different_colours_3_3.png'
import SadBeak from './beak/different_colours_4_4.png'
import AngryBeak from './beak/different_colours_5_5.png'
import GoldenBeak from './beak/gold_colour_1_1.png'
import LongMustache from './beak/beak_with_mustache_1_1.png'
import ShortMustache from './beak/beak_with_mustache_2_2.png'
import CigarBeak from './beak/beak_with_a_cigar.png'
import PipeBeak from './beak/beak_with_a_pipe.png'
import TongueOut from './beak/beak_with_tongue_out.png'
import Lipstick from './beak/lipstick_1.png'
import Piercing from './beak/piercing_1.png'
import BeakMask from './beak/running_mask.png'
import GoldenTooth from './beak/golden_teeth_2.png'
import SteamPunkBeak from './beak/steampunk_mask.png'


//TODO: Update PenguinRarity for all penguinAttributes
export const attributePreviewList: PenguinAttribute[] = [
    {
        attributeName: "Bowtie",
        attributeImage: Bowtie,
        penguinPart: PenguinPart.InnerBody,
        rarity: PenguinRarity.Common
    },
    {
        attributeName: "Fancy Bowtie",
        attributeImage: FancyBowtie,
        penguinPart: PenguinPart.InnerBody,
        rarity: PenguinRarity.Common
    },
    {
        attributeName: "Rapper Chains",
        attributeImage: ChainNecklace,
        penguinPart: PenguinPart.InnerBody,
        rarity: PenguinRarity.Rare4Realz
    },
    {
        attributeName: "Chinese Collar Shirt",
        attributeImage: ChineseCollarShirt,
        penguinPart: PenguinPart.InnerBody,
        rarity: PenguinRarity.Uncommon
    },
    {
        attributeName: "Cook Cloth",
        attributeImage: CookCloath,
        penguinPart: PenguinPart.InnerBody,
        rarity: PenguinRarity.Uncommon
    },
    {
        attributeName: "Gentleman's Vest",
        attributeImage: GentlemanVest,
        penguinPart: PenguinPart.InnerBody,
        rarity: PenguinRarity.RareRare
    },
    {
        attributeName: "Necklace",
        attributeImage: GirlsNecklace,
        penguinPart: PenguinPart.InnerBody,
        rarity: PenguinRarity.KindaRare
    },
    {
        attributeName: "Dat Gold",
        attributeImage: GoldenBody,
        penguinPart: PenguinPart.InnerBody,
        rarity: PenguinRarity.Common
    },
    {
        attributeName: "Hawaiin Shirt",
        attributeImage: HawaiinShirt,
        penguinPart: PenguinPart.InnerBody,
        rarity: PenguinRarity.Rare4Realz
    },
    {
        attributeName: "Jean Vest",
        attributeImage: JeanVest,
        penguinPart: PenguinPart.InnerBody,
        rarity: PenguinRarity.RareRare
    },
    {
        attributeName: "Office Shirt",
        attributeImage: OfficeShirt,
        penguinPart: PenguinPart.InnerBody,
        rarity: PenguinRarity.Uncommon
    },
    {
        attributeName: "Graduation Scarf",
        attributeImage: GraduationScarf,
        penguinPart: PenguinPart.InnerBody,
        rarity: PenguinRarity.KindaRare
    },
    {
        attributeName: "Blue T-Shirt",
        attributeImage: BlueTShirt,
        penguinPart: PenguinPart.InnerBody,
        rarity: PenguinRarity.Common
    },
    {
        attributeName: "Heart T-Shirt",
        attributeImage: HeartTShirt,
        penguinPart: PenguinPart.InnerBody,
        rarity: PenguinRarity.KindaRare
    },
    {
        attributeName: "World T-Shirt",
        attributeImage: WorldTShirt,
        penguinPart: PenguinPart.InnerBody,
        rarity: PenguinRarity.RareRare
    },
    {
        attributeName: "Skull T-Shirt",
        attributeImage: SkullTShirt,
        penguinPart: PenguinPart.InnerBody,
        rarity: PenguinRarity.Common
    },
    {
        attributeName: "Neck Tie",
        attributeImage: NeckTie,
        penguinPart: PenguinPart.InnerBody,
        rarity: PenguinRarity.Common
    },
    {
        attributeName: "Winter Scarf",
        attributeImage: WinterScarf,
        penguinPart: PenguinPart.InnerBody,
        rarity: PenguinRarity.Uncommon
    },
    {
        attributeName: "Devil's Tail",
        attributeImage: DevilsTail,
        penguinPart: PenguinPart.OuterBody,
        rarity: PenguinRarity.Common
    },
    {
        attributeName: "Fur",
        attributeImage: Fur,
        penguinPart: PenguinPart.OuterBody,
        rarity: PenguinRarity.Common
    },
    {
        attributeName: "Dat Gold",
        attributeImage: GoldenOuterBody,
        penguinPart: PenguinPart.OuterBody,
        rarity: PenguinRarity.RareRare
    }, 
    {
        attributeName: "Dat Blue",
        attributeImage: OuterBodyBlue,
        penguinPart: PenguinPart.OuterBody,
        rarity: PenguinRarity.Common
    }, 
    {
        attributeName: "Dat Green",
        attributeImage: OuterBodyGreen,
        penguinPart: PenguinPart.OuterBody,
        rarity: PenguinRarity.Common
    }, 
    {
        attributeName: "Dat Grey",
        attributeImage: OuterBodyGrey,
        penguinPart: PenguinPart.OuterBody,
        rarity: PenguinRarity.Common
    }, 
    {
        attributeName: "Dat Orange",
        attributeImage: OuterBodyOrange,
        penguinPart: PenguinPart.OuterBody,
        rarity: PenguinRarity.Uncommon
    }, 
    {
        attributeName: "Dat Pink",
        attributeImage: OuterBodyPink,
        penguinPart: PenguinPart.OuterBody,
        rarity: PenguinRarity.Uncommon
    }, 
    {
        attributeName: "Dat Purple",
        attributeImage: OuterBodyPurple,
        penguinPart: PenguinPart.OuterBody,
        rarity: PenguinRarity.Uncommon
    }, 
    {
        attributeName: "Dat Red",
        attributeImage: OuterBodyRed,
        penguinPart: PenguinPart.OuterBody,
        rarity: PenguinRarity.KindaRare
    },
    {
        attributeName: "Leopard Print",
        attributeImage: LeopardPrint,
        penguinPart: PenguinPart.OuterBody,
        rarity: PenguinRarity.Rare4Realz
    },
    {
        attributeName: "Pirate Hook",
        attributeImage: PirateHook,
        penguinPart: PenguinPart.OuterBody,
        rarity: PenguinRarity.Common
    },
    {
        attributeName: "Dat Rainbow",
        attributeImage: RainbowBody,
        penguinPart: PenguinPart.OuterBody,
        rarity: PenguinRarity.Common
    },
    {
        attributeName: "Superhero Cloak",
        attributeImage: SuperheroCloak,
        penguinPart: PenguinPart.OuterBody,
        rarity: PenguinRarity.Rare4Realz
    },
    {
        attributeName: "Viking Bracelet",
        attributeImage: VikingBracelet,
        penguinPart: PenguinPart.OuterBody,
        rarity: PenguinRarity.KindaRare
    },
    {
        attributeName: "Winter Gloves",
        attributeImage: WinterGloves,
        penguinPart: PenguinPart.OuterBody,
        rarity: PenguinRarity.RareRare
    }, 
    {
        attributeName: "Wizards Cloak",
        attributeImage: WizardsCloak,
        penguinPart: PenguinPart.OuterBody,
        rarity: PenguinRarity.Uncommon
    }, 
    {
        attributeName: "Zombie Bites",
        attributeImage: ZombieBites,
        penguinPart: PenguinPart.OuterBody,
        rarity: PenguinRarity.Uncommon
    }, 
    {
        attributeName: "Dat Hat",
        attributeImage: DatHat,
        penguinPart: PenguinPart.Head,
        rarity: PenguinRarity.Common
    }, 
    {
        attributeName: "Beanie",
        attributeImage: Beanie,
        penguinPart: PenguinPart.Head,
        rarity: PenguinRarity.KindaRare
    }, 
    {
        attributeName: "Cook Hat",
        attributeImage: CookHat,
        penguinPart: PenguinPart.Head,
        rarity: PenguinRarity.Uncommon
    }, 
    {
        attributeName: "Cowboy Hat",
        attributeImage: CowboyHat,
        penguinPart: PenguinPart.Head,
        rarity: PenguinRarity.Rare4Realz
    }, 
    {
        attributeName: "Gentleman Hat",
        attributeImage: GentlemanHat,
        penguinPart: PenguinPart.Head,
        rarity: PenguinRarity.Common
    }, 
    {
        attributeName: "Summer Hat",
        attributeImage: SummerHat,
        penguinPart: PenguinPart.Head,
        rarity: PenguinRarity.Common
    }, 
    {
        attributeName: "Graduation Cap",
        attributeImage: GraduationCap,
        penguinPart: PenguinPart.Head,
        rarity: PenguinRarity.Uncommon
    }, 
    {
        attributeName: "Sherlock Cap",
        attributeImage: SherlockCap,
        penguinPart: PenguinPart.Head,
        rarity: PenguinRarity.Common
    }, 
    {
        attributeName: "Horns",
        attributeImage: Horns,
        penguinPart: PenguinPart.Head,
        rarity: PenguinRarity.Common
    }, 
    {
        attributeName: "Magician Hat",
        attributeImage: OldMagicianHat,
        penguinPart: PenguinPart.Head,
        rarity: PenguinRarity.Uncommon
    }, 
    {
        attributeName: "Chinese Hat",
        attributeImage: OldSchoolChineseHat,
        penguinPart: PenguinPart.Head,
        rarity: PenguinRarity.KindaRare
    },
    {
        attributeName: "Pirate Hat",
        attributeImage: PirateHat,
        penguinPart: PenguinPart.Head,
        rarity: PenguinRarity.Common
    }, 
    {
        attributeName: "Police Hat",
        attributeImage: PoliceHat,
        penguinPart: PenguinPart.Head,
        rarity: PenguinRarity.RareRare
    }, 
    {
        attributeName: "Hat",
        attributeImage: RegularHat,
        penguinPart: PenguinPart.Head,
        rarity: PenguinRarity.Common
    }, 
    {
        attributeName: "Sailor Hat",
        attributeImage: SailorHat,
        penguinPart: PenguinPart.Head,
        rarity: PenguinRarity.Common
    }, 
    {
        attributeName: "Santa Hat",
        attributeImage: SantaHat,
        penguinPart: PenguinPart.Head,
        rarity: PenguinRarity.Common
    }, 
    {
        attributeName: "Tiara",
        attributeImage: Tiara,
        penguinPart: PenguinPart.Head,
        rarity: PenguinRarity.Common
    }, 
    {
        attributeName: "Vikings Helmet",
        attributeImage: VikingsHelmet,
        penguinPart: PenguinPart.Head,
        rarity: PenguinRarity.KindaRare
    }, 
    {
        attributeName: "Warrior Helmet",
        attributeImage: WarriorHelmet,
        penguinPart: PenguinPart.Head,
        rarity: PenguinRarity.Uncommon
    }, 
    {
        attributeName: "Wizard Hat",
        attributeImage: WizardHat,
        penguinPart: PenguinPart.Head,
        rarity: PenguinRarity.RareRare
    }, 
    {
        attributeName: "Angry",
        attributeImage: AngryEyes,
        penguinPart: PenguinPart.Eyes,
        rarity: PenguinRarity.Common
    }, 
    {
        attributeName: "Happy",
        attributeImage: HappyEyes,
        penguinPart: PenguinPart.Eyes,
        rarity: PenguinRarity.Common
    }, 
    {
        attributeName: "Sad",
        attributeImage: SadEyes,
        penguinPart: PenguinPart.Eyes,
        rarity: PenguinRarity.Common
    }, 
    {
        attributeName: "Surprised",
        attributeImage: SurprisedEyes,
        penguinPart: PenguinPart.Eyes,
        rarity: PenguinRarity.Common
    }, 
    {
        attributeName: "Suspicious",
        attributeImage: SuspiciousEyes,
        penguinPart: PenguinPart.Eyes,
        rarity: PenguinRarity.Common
    }, 
    {
        attributeName: "Wink",
        attributeImage: WinkEyes,
        penguinPart: PenguinPart.Eyes,
        rarity: PenguinRarity.Common
    }, 
    {
        attributeName: "Nerdy Glasses",
        attributeImage: NerdyGlasses,
        penguinPart: PenguinPart.Eyes,
        rarity: PenguinRarity.KindaRare
    }, 
    {
        attributeName: "Daft Punk Glasses",
        attributeImage: DaftPunk,
        penguinPart: PenguinPart.Eyes,
        rarity: PenguinRarity.RareRare
    }, 
    {
        attributeName: "Eye Patch",
        attributeImage: EyePatch,
        penguinPart: PenguinPart.Eyes,
        rarity: PenguinRarity.RareRare
    }, 
    {
        attributeName: "Eyelashes",
        attributeImage: Eyelashes,
        penguinPart: PenguinPart.Eyes,
        rarity: PenguinRarity.Common
    }, 
    {
        attributeName: "Scar",
        attributeImage: ScarEyes,
        penguinPart: PenguinPart.Eyes,
        rarity: PenguinRarity.Uncommon
    },
    {
        attributeName: "Eye Mask",
        attributeImage: EyeMasks,
        penguinPart: PenguinPart.Eyes,
        rarity: PenguinRarity.Uncommon
    }, 
    {
        attributeName: "Modern Sunglasses",
        attributeImage: ModernSunglasses,
        penguinPart: PenguinPart.Eyes,
        rarity: PenguinRarity.Uncommon
    }, 
    {
        attributeName: "Study Glasses",
        attributeImage: NarrowGlasses,
        penguinPart: PenguinPart.Eyes,
        rarity: PenguinRarity.Common
    }, 
    {
        attributeName: "Detective Glasses",
        attributeImage: OneEyeGlass,
        penguinPart: PenguinPart.Eyes,
        rarity: PenguinRarity.KindaRare
    }, 
    {
        attributeName: "Fun Glasses",
        attributeImage: RoundSunglasses,
        penguinPart: PenguinPart.Eyes,
        rarity: PenguinRarity.Uncommon
    }, 
    {
        attributeName: "SteamPunk Glasses",
        attributeImage: SteamPunk,
        penguinPart: PenguinPart.Eyes,
        rarity: PenguinRarity.Rare4Realz
    }, 
    {
        attributeName: "Sunglasses",
        attributeImage: Sunglasses,
        penguinPart: PenguinPart.Eyes,
        rarity: PenguinRarity.KindaRare
    },
    {
        attributeName: "Dat Red",
        attributeImage: RedFeet,
        penguinPart: PenguinPart.Feet,
        rarity: PenguinRarity.Common
    }, 
    {
        attributeName: "Dat Green",
        attributeImage: GreenFeet,
        penguinPart: PenguinPart.Feet,
        rarity: PenguinRarity.Common
    }, 
    {
        attributeName: "Dat Orange",
        attributeImage: OrangeFeet,
        penguinPart: PenguinPart.Feet,
        rarity: PenguinRarity.Common
    }, 
    {
        attributeName: "Dat Blue",
        attributeImage: BlueFeet,
        penguinPart: PenguinPart.Feet,
        rarity: PenguinRarity.Common
    }, 
    {
        attributeName: "Dat Grey",
        attributeImage: GreyFeet,
        penguinPart: PenguinPart.Feet,
        rarity: PenguinRarity.Common
    }, 
    {
        attributeName: "Dat Purple",
        attributeImage: PurpleFeet,
        penguinPart: PenguinPart.Feet,
        rarity: PenguinRarity.Common
    }, 
    {
        attributeName: "Dat Pink",
        attributeImage: PinkFeet,
        penguinPart: PenguinPart.Feet,
        rarity: PenguinRarity.Common
    }, 
    {
        attributeName: "Dat Yellow",
        attributeImage: YellowFeet,
        penguinPart: PenguinPart.Feet,
        rarity: PenguinRarity.Common
    }, 
    {
        attributeName: "Dat Gold",
        attributeImage: GoldenFeet,
        penguinPart: PenguinPart.Feet,
        rarity: PenguinRarity.Common
    }, 
    {
        attributeName: "Boots",
        attributeImage: Boots,
        penguinPart: PenguinPart.Feet,
        rarity: PenguinRarity.Uncommon
    }, 
    {
        attributeName: "Dat Converse",
        attributeImage: ConverseShoes,
        penguinPart: PenguinPart.Feet,
        rarity: PenguinRarity.Uncommon
    }, 
    {
        attributeName: "Formal Shoes",
        attributeImage: FormalShoes,
        penguinPart: PenguinPart.Feet,
        rarity: PenguinRarity.Uncommon
    }, 
    {
        attributeName: "Anklet",
        attributeImage: GirlyAnklet,
        penguinPart: PenguinPart.Feet,
        rarity: PenguinRarity.Common
    }, 
    {
        attributeName: "Old Leather Boots",
        attributeImage: OldLeatherBoots,
        penguinPart: PenguinPart.Feet,
        rarity: PenguinRarity.Rare4Realz
    }, 
    {
        attributeName: "Pirate Leg",
        attributeImage: PirateWoodLeg,
        penguinPart: PenguinPart.Feet,
        rarity: PenguinRarity.KindaRare
    }, 
    {
        attributeName: "Running Shoes",
        attributeImage: RunningShoes,
        penguinPart: PenguinPart.Feet,
        rarity: PenguinRarity.Common
    }, 
    {
        attributeName: "School Shoes",
        attributeImage: SchoolShoes,
        penguinPart: PenguinPart.Feet,
        rarity: PenguinRarity.Common
    },
    {
        attributeName: "Summer Slippers",
        attributeImage: SummerSlippers,
        penguinPart: PenguinPart.Feet,
        rarity: PenguinRarity.RareRare
    },
    {
        attributeName: "Dat Vans",
        attributeImage: Vans,
        penguinPart: PenguinPart.Feet,
        rarity: PenguinRarity.KindaRare
    }, 
    {
        attributeName: "Dat Beak",
        attributeImage: NormalBeak,
        penguinPart: PenguinPart.Beak,
        rarity: PenguinRarity.Common
    }, 
    {
        attributeName: "Happy",
        attributeImage: HappyBeak,
        penguinPart: PenguinPart.Beak,
        rarity: PenguinRarity.Common
    }, 
    {
        attributeName: "Surprised",
        attributeImage: SurprisedBeak,
        penguinPart: PenguinPart.Beak,
        rarity: PenguinRarity.Common
    }, 
    {
        attributeName: "Confused",
        attributeImage: ConfusedBeak,
        penguinPart: PenguinPart.Beak,
        rarity: PenguinRarity.Common
    }, 
    {
        attributeName: "Sad",
        attributeImage: SadBeak,
        penguinPart: PenguinPart.Beak,
        rarity: PenguinRarity.Common
    }, 
    {
        attributeName: "Angry",
        attributeImage: AngryBeak,
        penguinPart: PenguinPart.Beak,
        rarity: PenguinRarity.Common
    }, 
    {
        attributeName: "Dat Gold",
        attributeImage: GoldenBeak,
        penguinPart: PenguinPart.Beak,
        rarity: PenguinRarity.Common
    }, 
    {
        attributeName: "Long Mustache",
        attributeImage: LongMustache,
        penguinPart: PenguinPart.Beak,
        rarity: PenguinRarity.Uncommon
    }, 
    {
        attributeName: "Short Mustache",
        attributeImage: ShortMustache,
        penguinPart: PenguinPart.Beak,
        rarity: PenguinRarity.Uncommon
    }, 
    {
        attributeName: "Dat Smoke",
        attributeImage: CigarBeak,
        penguinPart: PenguinPart.Beak,
        rarity: PenguinRarity.KindaRare
    }, 
    {
        attributeName: "Dat Pipe",
        attributeImage: PipeBeak,
        penguinPart: PenguinPart.Beak,
        rarity: PenguinRarity.KindaRare
    }, 
    {
        attributeName: "Tongue Out",
        attributeImage: TongueOut,
        penguinPart: PenguinPart.Beak,
        rarity: PenguinRarity.KindaRare
    }, 
    {
        attributeName: "Lipstick",
        attributeImage: Lipstick,
        penguinPart: PenguinPart.Beak,
        rarity: PenguinRarity.KindaRare
    }, 
    {
        attributeName: "Piercing",
        attributeImage: Piercing,
        penguinPart: PenguinPart.Beak,
        rarity: PenguinRarity.RareRare
    }, 
    {
        attributeName: "Mask",
        attributeImage: BeakMask,
        penguinPart: PenguinPart.Beak,
        rarity: PenguinRarity.RareRare
    },
    {
        attributeName: "Golden Tooth",
        attributeImage: GoldenTooth,
        penguinPart: PenguinPart.Beak,
        rarity: PenguinRarity.Rare4Realz
    }, 
    {
        attributeName: "Steam Punk",
        attributeImage: SteamPunkBeak,
        penguinPart: PenguinPart.Beak,
        rarity: PenguinRarity.Rare4Realz
    }

]

