import { SwiperSlide } from 'swiper/react';
import Swiper from 'react-id-swiper';
import { attributePreviewList, PenguinPart, PenguinRarity } from '../../img/preview/AttributePreview';

import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/effect-coverflow/effect-coverflow.scss';

import AttributeSwiperItem from './AttributeSwiperItem/AttributeSwiperItem';

// import Swiper core and required modules. In this example,
// we will use Pagination and Coverflow
import SwiperCore, { Pagination, EffectCoverflow } from 'swiper';

// configure Swiper to use modules
SwiperCore.use([Pagination, EffectCoverflow]);

interface AttributeSwiperProps {
	selectedPart: PenguinPart;
	selectedRarity: PenguinRarity;
}

const AttributeSwiper = ({ selectedPart, selectedRarity }: AttributeSwiperProps) => {

	const itemsToRender = attributePreviewList
		.filter(item => item.penguinPart == selectedPart)
		.filter(item => item.rarity == selectedRarity);

	const swiperParams = {
		rebuildOnUpdate: true,
	};

	return (
		<>
			<Swiper
				effect="coverflow"
				grabCursor={true}
				centeredSlides={true}
				spaceBetween={0}
				slidesPerView={2}
				loop={true}
				preloadImages={true}
				coverflowEffect={{
					rotate: 20,
					stretch: 25,
					depth: 250,
					modifier: 1,
					slideShadows: false,
				}}
				breakpoints={{
					700: {
						spaceBetween: 0,
						slidesPerView: itemsToRender.length > 2 ? 2 : 1,
					},
					500: {
						spaceBetween: 100,
						slidesPerView: 1,
					},
					411: {
						spaceBetween: 100,
						slidesPerView: 1,
					},
					300: {
						spaceBetween: 0,
						slidesPerView: 1,
					},
				}}
				{...swiperParams}
			>
				{itemsToRender.map(item => (
					<SwiperSlide key={item.attributeName}>
						<AttributeSwiperItem imageUrl={item.attributeImage} name={item.attributeName} />
					</SwiperSlide>
				))}
			</Swiper>
		</>
	);
};

export default AttributeSwiper;
