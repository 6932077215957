import './MintSection.scss';
import NotConnectedComp from '../../img/comp/not_connected.png';
import ConnectedComp from '../../img/comp/connected.png';
import { useWeb3React } from '@web3-react/core';
import { toBech32 } from '@harmony-js/crypto';
import { isBech32Address } from '@harmony-js/utils';
import { useEffect, useState } from 'react';
import { Contract } from '@harmony-js/contract';
import { createDatPenguinClubContract, getDatPenguinClubContractFromConnector } from '../../helpers/contractHelper';
import { useHarmony } from '../../context/harmonyContext';
import { Unit } from '@harmony-js/utils';
import { toast } from 'react-toastify';
import PacmanLoader from "react-spinners/PacmanLoader";
import { registerNewTokenMintedToDb } from '../../db/readData';

enum MintingStatus {
	NotStarted = 'NotStarted',
	Started = 'Started',
	Ended = 'Ended',
}

const MintSection = () => {
	const { account, active, connector, library } = useWeb3React();
	const { hmy } = useHarmony();
	const parsedAccount = active && account && !isBech32Address(account) ? toBech32(account) : account;
	const [isPasswordEntered, setIsPasswordEntered] = useState(false);
	const [isMintingStarted, setIsMintingStarted] = useState(false);
	const [mintingStatus, setMintingStatus] = useState(MintingStatus.NotStarted);
	const [contract, setContract] = useState<Contract | null>(createDatPenguinClubContract(hmy));

	useEffect(() => {
		if (!account) {
			setContract(null);
		}
	}, [account]);

	useEffect(() => {
		if (connector) {
			(async () => {
				const contract = await getDatPenguinClubContractFromConnector(connector, library);
				setContract(contract);
			})();
		}
	}, [connector, setContract]);

	const handleMintClick = () => async () => {
		if (account && contract) {
			try {
				setMintingStatus(MintingStatus.Started)
				await contract.methods.safeMint().send({
					from: account,
					gasPrice: 30000000000,
					gasLimit: 21000000,
					value: new Unit('250').asOne().toWei(),
				});
				toast.success('Transaction done');
				setMintingStatus(MintingStatus.Ended)
				await registerNewTokenMintedToDb(parsedAccount);
			} catch (error) {
				setMintingStatus(MintingStatus.NotStarted)
				toast.error(error);
			}
		} else {
			setMintingStatus(MintingStatus.NotStarted)
			toast.error('Connect your wallet');
		}
	};

	useEffect(() => {
		setIsMintingStarted(true);
	}, []);

	const handlePasswordInputChanges = (input: string) => {
		if (input !== null && input !== undefined && input.toLowerCase().trim() === 'letsgo') {
			setIsPasswordEntered(true);
		} else {
			setIsPasswordEntered(false);
		}
	};

	return (
		<div className="mint-section-container">
			<img src={parsedAccount ? ConnectedComp : NotConnectedComp} alt="The Penguin Press" className="comp-image" />
			<div className="comp-text-container">
				{parsedAccount ? (
					isPasswordEntered ? (
						isMintingStarted ? (
							mintingStatus === MintingStatus.NotStarted ? (
								<p className="mint-button" onClick={handleMintClick()}>
									MINT
								</p>
							) : mintingStatus === MintingStatus.Started ? (
								<div className="loading-container">
									<PacmanLoader color={"#ff678e"} loading={true} size={40}/>
								</div>
							) : (
								<p className="minting-not-started-error-message">Transaction Successful! Reload the page to mint another one</p>
							)
						) : (
							<p className="minting-not-started-error-message">Minting starts on November 10, 6:00pm (PST)</p>
						)
					) : (
						<input
							className="password-input"
							type="password"
							placeholder="Enter Password"
							onChange={event => handlePasswordInputChanges(event.target.value)}
						/>
					)
				) : (
					<p className="connection-error-message">
						512 &#128039; boarded! Connect your wallet by pressing the "Not Connected" indicator in the top bar
					</p>
				)}
			</div>
		</div>
	);
};

export default MintSection;
