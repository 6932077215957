import { db } from './init';
import { doc, getDoc, updateDoc, setDoc, arrayUnion, increment } from 'firebase/firestore';

export const getTokenIdsForWalletAddress = async (oneAddress: string): Promise<number[] | null> => {
	const docRef = doc(db, 'mintInfo', oneAddress);
	const docSnap = await getDoc(docRef);

	if (docSnap.exists()) {
		const tokenIds: number[] = docSnap.data().tokenId;
		return tokenIds;
	}

	return null;
};

export const registerNewTokenMintedToDb = async (oneAddress: string | null | undefined) => {
	if (oneAddress !== null && oneAddress !== undefined) {
		const docRef = doc(db, 'mintInfo', 'tokenCounter');
		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			const nextTokenId = docSnap.data().nextTokenId;

			const addressDocRef = doc(db, 'mintInfo', oneAddress);
			const addressDocSnap = await getDoc(addressDocRef);

			if (addressDocSnap.exists()) {
				await updateDoc(addressDocRef, {
					tokenId: arrayUnion(nextTokenId),
				});
			} else {
				await setDoc(addressDocRef, {
					tokenId: [nextTokenId],
				});
			}

			await updateDoc(docRef, {
				nextTokenId: increment(1),
			});
		}
	}
};
