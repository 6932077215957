import './AttributeSwiperItem.scss'

export interface SwiperItemProps {
    imageUrl: string;
    name: string;
}


export default ({ imageUrl, name }: SwiperItemProps) => {
    return (
        <div className="swiper-item-container">
            <p className="swipe-item-descriptor">{name}</p>
            <img src={imageUrl} alt={name} className="swipe-item-image" />
        </div>
    );
};








