import { useWeb3React } from '@web3-react/core';
import { useEffect, useState } from 'react';
import { getTokenIdsForWalletAddress } from '../../db/readData';
import { toBech32 } from '@harmony-js/crypto';
import { isBech32Address } from '@harmony-js/utils';
import './ViewSection.scss';
import { isNullOrEmpty } from '../../utils/validators';

type NetworkLoadingState = {
	state: 'Loading';
};

type NetworkFailedState = {
	state: 'Failed';
	errorMessage: string;
};

type NetworkSuccessState = {
	state: 'Success';
	viewSectionProperties: (ViewSectionProps | string)[];
};

interface ViewSectionProps {
	image: string;
	background: string;
	feet: string;
	outerBody: string;
	innerBody: string;
	innerBodyProps: string | null;
	outerBodyProps: string | null;
	eyes: string;
	head: string | null;
	beakExpressions: string;
}

const ViewSection = () => {
	const { account, active } = useWeb3React();
	const parsedAccount = active && account && !isBech32Address(account) ? toBech32(account) : account;
	const PINATA_GATEWAY_URL = 'https://gateway.pinata.cloud/ipfs/QmfZ6c7W7DqMNawawFVCotB6K9cbeoRYqPcP4U9pHddEmH';
	const [data, setData] = useState<NetworkLoadingState | NetworkFailedState | NetworkSuccessState>({
		state: 'Loading',
	});

	const getAttributeValue = (attributeList: { trait_type: string; value: string }[], attributeName: string): string => {
		if (isNullOrEmpty(attributeList)) {
			return 'None';
		}
		const result = attributeList.find(item => item.trait_type === attributeName);
		if (result !== null && result !== undefined) {
			return result.value;
		}
		return 'None';
	};

	const fetchTokenIdsFromDb = async () => {
		if (parsedAccount) {
			const tokenIds = await getTokenIdsForWalletAddress(parsedAccount);
			if (tokenIds == null) {
				setData({
					state: 'Failed',
					errorMessage: "You haven't minted any penguins yet ! If you have refresh this page",
				});
			} else {
				const ipfsUrls = tokenIds.map(item => `${PINATA_GATEWAY_URL}/${item}.json`);
				const responses: (ViewSectionProps | string)[] = await Promise.all(
					ipfsUrls.map(async entry => {
						try {
							const response = await fetch(entry, { mode: 'cors' });
							const jsonResponse = await response.json();
							const { attributes, image } = jsonResponse;
							return {
								image: image,
								background: getAttributeValue(attributes, 'Background'),
								feet: getAttributeValue(attributes, 'Feet'),
								outerBody: getAttributeValue(attributes, 'Outer Body'),
								innerBody: getAttributeValue(attributes, 'Inner Body'),
								innerBodyProps: getAttributeValue(attributes, 'Inner Body - Props'),
								outerBodyProps: getAttributeValue(attributes, 'Outer Body - Props'),
								eyes: getAttributeValue(attributes, 'Eyes'),
								head: getAttributeValue(attributes, 'Head'),
								beakExpressions: getAttributeValue(attributes, 'Beak Expressions'),
							};
						} catch {
							return 'Looks like the IPFS gateway where your penguins are stored are taking a bit longer to respond ! Please try again in a moment';
						}
					}),
				);
				setData({
					state: 'Success',
					viewSectionProperties: responses,
				});
			}
		} else {
			setData({
				state: 'Failed',
				errorMessage: 'Connect your wallet to view your penguins',
			});
		}
	};

	useEffect(() => {
		fetchTokenIdsFromDb();
	}, [account, active]);

	return (
		<>
			<h1 className="view-section-title">VIEW MY &#128039;</h1>
			{data.state === 'Success' ? (
				<div className="view-section-container">
					{data.viewSectionProperties.map((item: any) =>
						typeof item === 'string' ? (
							<div className="view-item-container">
								<p className="view-item-error-message">{item}</p>
							</div>
						) : (
							<div className="view-item-container">
								<img src={item.image} alt="Dat Penguin Image" className="view-item-image" />
								<div className="view-item-attribute-container">
									<p className="view-item-attribute-container__title">Background</p>
									<p className="view-item-attribute-container__text">{item.background}</p>
								</div>
								<div className="view-item-attribute-container">
									<p className="view-item-attribute-container__title">Feet</p>
									<p className="view-item-attribute-container__text">{item.feet}</p>
								</div>
								<div className="view-item-attribute-container">
									<p className="view-item-attribute-container__title">Outer Body</p>
									<p className="view-item-attribute-container__text">{item.outerBody}</p>
								</div>
								<div className="view-item-attribute-container">
									<p className="view-item-attribute-container__title">Inner Body</p>
									<p className="view-item-attribute-container__text">{item.innerBody}</p>
								</div>
								<div className="view-item-attribute-container">
									<p className="view-item-attribute-container__title">Inner Body - Props</p>
									<p className="view-item-attribute-container__text">{item.innerBodyProps}</p>
								</div>
								<div className="view-item-attribute-container">
									<p className="view-item-attribute-container__title">Outer Body - Props</p>
									<p className="view-item-attribute-container__text">{item.outerBodyProps}</p>
								</div>
								<div className="view-item-attribute-container">
									<p className="view-item-attribute-container__title">Eyes</p>
									<p className="view-item-attribute-container__text">{item.eyes}</p>
								</div>
								<div className="view-item-attribute-container">
									<p className="view-item-attribute-container__title">Head</p>
									<p className="view-item-attribute-container__text">{item.head}</p>
								</div>
								<div className="view-item-attribute-container">
									<p className="view-item-attribute-container__title">Beak</p>
									<p className="view-item-attribute-container__text">{item.beakExpressions}</p>
								</div>
							</div>
						),
					)}
				</div>
			) : data.state === 'Loading' ? (
				'Loading...'
			) : (
				data.errorMessage
			)}
		</>
	);
};

export default ViewSection;
