import Collapsible from "react-collapsible";
import './FAQItem.scss'


export interface FAQItemProps {
    title: string;
    description: string;
}



const FAQItem = ({ title, description }: FAQItemProps) => {
    return (
        <Collapsible trigger={title}>
            <p className="faq-item-description">
                {description}
            </p>
        </Collapsible>
    );
}


export default FAQItem;