import styled from 'styled-components';
import { media } from '../../utils/cssConstants';
import FAQItem from '../FAQItem';

const FAQSection = () => {
	return (
		<>
			<FAQItemContainer>
				<FAQTitle>FAQ</FAQTitle>
				<FAQItem
					title={'HOW CAN I BUY DAT PENGUIN?'}
					description={`Purchasing a penguin is done through our 'Penguin Press' which can be found in the 'Mint' Section of the website. When minting commences, login to the computer with our very very secure "password", connect your wallet. Follow the prompted procedures! That's it, welcome to Dat Penguin Club`}
				/>
				<FAQItem
					title={'WHEN DOES IT DROP?'}
					description={'The drop date is November 10, 6:00pm (PST). Also follow our social channels for exact times'}
				/>
				<FAQItem title={'HOW MUCH?'} description={'The mint price is set at 250 ONE'} />
				<FAQItem
					title={`I CAN'T VIEW MY NFT`}
					description={`Go to the HRC721 section of the wallet. If you don't see 'Dat Penguin Club', click the + icon at the bottom right and in the 'Contract Address' section paste this address ${process.env.REACT_APP_FRONTEND_NETWORK === 'mainnet' ? 'one12cxwyrr60egskwx04t2kptc6fuputthfl88avu' : 'one1x4u09xns729tejgvcvtytq3apf50wp88sdtky7'} and the 'Contract Name' will be automatically populated or you could type in 'Dat Penguin Club' as the name. Now click on that collection and voila - there are all your NFTs you minted with us.`}
				/>
				<FAQItem
					title={'IS THERE A LIMIT?'}
					description={
						'We will be limiting people to mint 1 penguin per transaction. However, this does mean you can return to the mint section as many times as you want.'
					}
				/>
				<FAQItem
					title={'HOW MANY TRAITS ARE THERE?'}
					description={
						'There are around 107 possible traits with 6 total different categories (Head, Outer Body, Inner Body, (Happy) Feet, Eyes, Beak). You can see them in all their glory in the attributes section of the website.'
					}
				/>
				<FAQItem
					title={`DO YOU HAVE A RARITY SYSTEM?`}
					description={
						'Yes, rarity is very important to us. Our rarity system has 5 different rarities. Common (50% chance of occurence), Uncommon (20% chance of occurence), Kinda Rare (15% chance of occurence), Rare Rare (10% chance of occurence) and Rare 4 Realz (5% chance of occurence)'
					}
				/>
				<FAQItem
					title={`WILL THERE BE A SECONDARY MARKET?`}
					description={
						'Yes, we will do our best to be listing on DaVinci as soon as we go live. However, we will look to move over to our own marketplace in the near future'
					}
				/>
				<FAQItem
					title={`WILL THERE BE A COMMUNITY FUND?`}
					description={`Yes, we will be placing 20% of our total sales into our 'community vault'. This is primarily so that we can grow our team and build an ecosystem around dat penguin club`}
				/>
				<FAQItem
					title={`DO I OWN THE PENGUIN AFTER PURCHASING?`}
					description={`Yes, full intellectual properties are given to the buyer.`}
				/>
			</FAQItemContainer>
		</>
	);
};

const FAQItemContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 1.5em;
	margin-bottom: 2.5em;
`;

const FAQTitle = styled.h1`
	margin: 1em 0em 0em 0em;
	padding: 0;
	text-align: center;
	font-size: 5em;

	${media.tablets} {
		font-size: 4em;
	}

	${media.mobilePortrait} {
		font-size: 3.5em;
	}
`;

export default FAQSection;
