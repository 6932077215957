import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import BackgroundImage from './img/web_background.svg';

import 'react-toastify/dist/ReactToastify.css';
import { ScrollingProvider, Section } from 'react-scroll-section';
import StaticMenu from './components/StaticMenu';
import AboutSection from './components/Sections/AboutSection';
import AttributeSection from './components/Sections/AttributeSection';
import FAQSection from './components/Sections/FAQSection';
import MintSection from './components/Sections/MintSection';
import TwitterIcon from './img/icons/twitter.png';
import DiscordIcon from './img/icons/discord.png';
import Snowfall from 'react-snowfall';
import ViewSection from './components/Sections/ViewSection';

const App = () => {

	return (
		<Wrapper>
			<Snowfall
				color={"#dee4fd"}
				snowflakeCount={200}
				radius={[0.5, 3.0]}
				speed={[0.5, 3.0]}
				wind={[-0.5, 2.0]}
			/>
			<ScrollingProvider offset={-120} scrollBehavior={'smooth'}>
				<StaticMenu />
				<Section id="about">
					<SectionContainer style={{ minHeight: 'fit-content' }}>
						<AboutSection />{' '}
					</SectionContainer>
				</Section>
				<Section id="mint">
					<SectionContainer style={{ minHeight: 'fit-content' }}>
						<MintSection />
					</SectionContainer>
				</Section>
				<Section id="attributes">
					<SectionContainer style={{ minHeight: 'fit-content' }}>
						<AttributeSection />
					</SectionContainer>
				</Section>
				<Section id="view">
					<SectionContainer style={{ minHeight: 'fit-content' }}>
						<ViewSection />
					</SectionContainer>
				</Section>
				<Section id="faq">
					<SectionContainer>
						<FAQSection />
					</SectionContainer>
				</Section>
				<Footer>
					<a href="https://twitter.com/DatPenguinClub" target="_blank">
						<img src={TwitterIcon} alt="Twitter Logo" style={{ width: '40px', height: '40px', cursor: 'pointer' }} />
					</a>
					<a href="https://discord.gg/SgQf6VMuXA" target="_blank">
						<img src={DiscordIcon} alt="Discord Logo" style={{ width: '40px', height: '40px', cursor: 'pointer' }} />
					</a>
				</Footer>
			</ScrollingProvider>
			<ToastContainer
				position="bottom-right"
				newestOnTop={false}
				pauseOnFocusLoss={false}
				pauseOnHover={false}
				rtl={false}
			/>
		</Wrapper>
	);
};

const Footer = styled.div`
	padding: 2em 0em;
	backdrop-filter: saturate(180%) blur(10px);
	display: flex;
	justify-content: center;
	gap: 1em;
`;

const SectionContainer = styled.div`
	max-width: 100%;
	min-height: calc(100vh - 120px);
	padding: 0 10%;
	overflow-x: hidden;
`;

const Wrapper = styled.div`
	background-image: url(${BackgroundImage});
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	font-size: 1rem;
	color: white;
`;

export default App;
