import { useState } from 'react';
import styled from 'styled-components';
import { PenguinPart, PenguinRarity } from '../../img/preview/AttributePreview';
import { media } from '../../utils/cssConstants';
import AttributeSwiper from '../Swiper/AttributeSwiper';

const AttributeSection = () => {
	const [penguinPart, setPenguinPart] = useState(PenguinPart.Head);
	const [penguinRarity, setRarity] = useState(PenguinRarity.Common);

	return (
		<AttributeSectionContainer>
			<BodyPartSection>
				<BodyPartHeader
					onClick={() => {
						setPenguinPart(PenguinPart.Head);
					}}
					selected={penguinPart == PenguinPart.Head}
				>
					<BodyHeaderNumber>01</BodyHeaderNumber>
					<BodyHeaderText>HEAD</BodyHeaderText>
				</BodyPartHeader>
				<BodyPartHeader
					onClick={() => {
						setPenguinPart(PenguinPart.OuterBody);
					}}
					selected={penguinPart == PenguinPart.OuterBody}
				>
					<BodyHeaderNumber>02</BodyHeaderNumber>
					<BodyHeaderText>OUTER BODY</BodyHeaderText>
				</BodyPartHeader>
				<BodyPartHeader
					onClick={() => {
						setPenguinPart(PenguinPart.InnerBody);
					}}
					selected={penguinPart == PenguinPart.InnerBody}
				>
					<BodyHeaderNumber>03</BodyHeaderNumber>
					<BodyHeaderText>INNER BODY</BodyHeaderText>
				</BodyPartHeader>
				<BodyPartHeader
					onClick={() => {
						setPenguinPart(PenguinPart.Feet);
					}}
					selected={penguinPart == PenguinPart.Feet}
				>
					<BodyHeaderNumber>04</BodyHeaderNumber>
					<BodyHeaderText>(HAPPY) FEET</BodyHeaderText>
				</BodyPartHeader>
				<BodyPartHeader
					onClick={() => {
						setPenguinPart(PenguinPart.Eyes);
					}}
					selected={penguinPart == PenguinPart.Eyes}
				>
					<BodyHeaderNumber>05</BodyHeaderNumber>
					<BodyHeaderText>EYES</BodyHeaderText>
				</BodyPartHeader>
				<BodyPartHeader
					onClick={() => {
						setPenguinPart(PenguinPart.Beak);
					}}
					selected={penguinPart == PenguinPart.Beak}
				>
					<BodyHeaderNumber>06</BodyHeaderNumber>
					<BodyHeaderText>BEAK</BodyHeaderText>
				</BodyPartHeader>
			</BodyPartSection>
			<AttributeSectionContent>
				<AttributeSectionDescription>
					All penguins are equally awesome, but some penguins are more equal than others. There are around 100 total
					traits in the collection and these have been categorized into a tier based system. Swipe around in the
					carousel below to view them all
				</AttributeSectionDescription>

				<RaritySelectorContainer>
					<RaritySelector
						onClick={() => {
							setRarity(PenguinRarity.Common);
						}}
						selected={penguinRarity == PenguinRarity.Common}
					>
						COMMON
					</RaritySelector>

					<RaritySelector
						onClick={() => {
							setRarity(PenguinRarity.Uncommon);
						}}
						selected={penguinRarity == PenguinRarity.Uncommon}
					>
						UNCOMMON
					</RaritySelector>

					<RaritySelector
						onClick={() => {
							setRarity(PenguinRarity.KindaRare);
						}}
						selected={penguinRarity == PenguinRarity.KindaRare}
					>
						KINDA RARE
					</RaritySelector>

					<RaritySelector
						onClick={() => {
							setRarity(PenguinRarity.RareRare);
						}}
						selected={penguinRarity == PenguinRarity.RareRare}
					>
						RARE RARE
					</RaritySelector>

					<RaritySelector
						onClick={() => {
							setRarity(PenguinRarity.Rare4Realz);
						}}
						selected={penguinRarity == PenguinRarity.Rare4Realz}
					>
						RARE 4 REALZ
					</RaritySelector>
				</RaritySelectorContainer>
				<AttributeSwiperContainer>
					<AttributeSwiper selectedPart={penguinPart} selectedRarity={penguinRarity} />
				</AttributeSwiperContainer>
			</AttributeSectionContent>
		</AttributeSectionContainer>
	);
};

const AttributeSwiperContainer = styled.div`
	margin-top: 2em;
`;

const RaritySelector = styled.div<{ selected: boolean }>`
	font-weight: normal;
	text-align: center;
	font-size: 1.5em;
	flex-grow: 1;
	padding: 0.5em 1em;
	cursor: pointer;

	border: ${props =>( props.selected ? '2.3px solid #303030' : 'none')};
	box-sizing: border-box;
	border-radius: ${props => (props.selected ? '0.5em' : '0em')};
	backdrop-filter: ${props => ( props.selected ? 'saturate(180%) blur(10px)' : 'none')};

	&:hover {
		border: 2.3px solid #303030;
		box-sizing: border-box;
		border-radius: 0.5em;
		backdrop-filter: saturate(180%) blur(10px);
	}

	${media.mobileLandscape} {
		font-size: 1.25em;
	}
`;

const RaritySelectorContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-top: 2em;
`;

const AttributeSectionContainer = styled.div`
	margin-top: 3em;
	display: grid;
	grid-template-columns: auto 1fr;
	grid-template-areas:
		'parts view'
		'. view';
	grid-column-gap: 1em;

	${media.tablets} {
		grid-template-columns: 1fr;
		grid-template-areas:
			'parts'
			'view';
	}

	${media.mobileLandscape} {
		margin-top: 2em;
	}
`;

const AttributeSectionDescription = styled.p`
	margin: 0;
	padding: 0em 2em;
	font-weight: 400;
	text-align: center;
	font-size: 1.5em;
	letter-spacing: 0.02em;
	color: rgba(255, 255, 255, 0.35);

	${media.largeDisplays} {
		padding: 0em 1em;
		font-size: 1.25em;
	}

	${media.mobilePortrait} {
		margin: 1em 0em 0em 0em;
		font-size: 1em;
	}
`;

const AttributeSectionContent = styled.div`
	overflow-x: hidden;
	grid-area: view;
`;

const BodyPartSection = styled.div`
	width: fit-content;
	height: 100%;
	grid-area: parts;

	${media.tablets} {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-column-gap: 1em;
	}

	${media.mobileLandscape} {
		grid-column-gap: 0.5em;
	}

	${media.mobilePortrait} {
		grid-template-columns: 1fr;
		width: 100%;
		text-align: center;
	}
`;

const BodyPartHeader = styled.div<{selected: boolean}>`
	width: max-content;
	padding: 2em 0em;
	cursor: pointer;
	color: ${props => (props.selected ? '#ff678e' : 'white')};

	&:hover h3 {
		color: #ff678e;
	}

	${media.mobilePortrait} {
		padding: 0;
		width: 100%;
	}
`;

const BodyHeaderNumber = styled.p`
	padding: 0;
	margin: 0em 0em 1em 0em;
	color: #ff678e;
	font-weight: 500;
	letter-spacing: 0.06em;
	font-size: 1em;

	${media.mobileLandscape} {
		font-size: 0.75em;
	}
`;

const BodyHeaderText = styled.h3`
	padding: 0;
	margin: 0em 0.5em 0em 0em;
	letter-spacing: 0.01em;
	font-weight: 500;
	font-size: 2em;

	${media.largeDisplays} {
		font-size: 1.5em;
	}

	${media.mobileLandscape} {
		font-size: 1.25em;
	}

	${media.mobilePortrait} {
		margin: 0em;
	}
`;

export default AttributeSection;
