import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCHmj5-4oQpQauMNCtl-WXSOQrtziOd8uk",
  authDomain: "main-dat-penguin-club.firebaseapp.com",
  projectId: "main-dat-penguin-club",
  storageBucket: "main-dat-penguin-club.appspot.com",
  messagingSenderId: "1081902622968",
  appId: "1:1081902622968:web:be40365c1bcb97bb1eb30d"
};

const firebaseApp = initializeApp(firebaseConfig);

export const db = getFirestore(firebaseApp);