import { useScrollSection } from 'react-scroll-section';
import styled from 'styled-components';
import { media } from '../utils/cssConstants';
import  ConnectWallet from './Wallet/ConnectWallet/ConnectWallet';
import NavigationButton from '../img/icons/nav_button.svg';

interface MenuItemProps {
	selected: boolean;
}

const StaticMenu = () => {
	const aboutSection = useScrollSection('about');
	const mintSection = useScrollSection('mint');
	const attributeSection = useScrollSection('attributes');
	const faqSection = useScrollSection('faq');
	const viewSection = useScrollSection('view');

	return (
		<ItemContainer>
			<NavItemContainer>
				<img src={NavigationButton} alt="Navigation Button" />
			</NavItemContainer>
			<Item onClick={aboutSection.onClick} selected={aboutSection.selected}>
				ABOUT
			</Item>
			<Item onClick={mintSection.onClick} selected={mintSection.selected}>
				MINT
			</Item>
			<Item onClick={attributeSection.onClick} selected={attributeSection.selected}>
				ATTRIBUTES
			</Item>
			<Item onClick={viewSection.onClick} selected={viewSection.selected}>
				VIEW
			</Item>
			<Item onClick={faqSection.onClick} selected={faqSection.selected}>
				FAQ
			</Item>
			<ConnectWallet />
		</ItemContainer>
	);
};

const NavItemContainer = styled.div`
	display: none;

	${media.tablets} {
    	//display: flex;
		width: 3em;
		height: 3em;

		position: absolute;
		left: 2em;
		vertical-align: middle;

		img {
			width: 100%;
		}
	}

  ${media.extraSmall} {
    left: 1em;
  }




`;

const ItemContainer = styled.ul`
	position: sticky;
	left: 0;
	top: 0;
	margin: 0;
	min-height: 120px;
	backdrop-filter: saturate(180%) blur(10px);
	display: flex;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	padding: 0px;
	z-index: 100;

	${media.tablets} {
		position: relative;
	}


`;

const Item = styled.li<MenuItemProps>`
	display: inline-block;
	font-style: normal;
	font-weight: 500;
	text-align: center;
	cursor: pointer;
	margin-right: 3em;

	&:last-child {
		margin-right: 0em !important;
	}

	&::after {
		content: '';
		display: block;
		margin: 0 auto;
		width: 80%;
		padding-top: 8px;
		border-bottom: ${props => (props.selected ? '3px solid #B2405D' : 'none')};
	}

	${media.tablets} {
		display: none;
	}
`;

export default StaticMenu;
