import { useState } from 'react';
import Modal from 'react-modal';
import './ConnectWallet.scss';
import CloseButton from '../../../img/icons/close_icon.svg';
import { ConnectorNames, connectorsByName } from '../../../utils/connectors';
import { mapWallets } from '../../../helpers/walletHelpers';
import { useWeb3React } from '@web3-react/core';
import { AbstractConnector } from '@web3-react/abstract-connector';
import { toBech32 } from '@harmony-js/crypto';
import { isBech32Address } from '@harmony-js/utils';
import {isMobile} from 'react-device-detect';


export enum ConnectionStatus {
	Connected = 'CONNECTED',
	NotConnected = 'NOT CONNECTED',
}

export default () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const isOneWalletInstalled = window.onewallet && window.onewallet.isOneWallet;
	const isMathWalletInstalled = window.harmony && window.harmony.isMathWallet;
	const isEthereumInstalled = window.ethereum;
	const { activate, deactivate, account, active } = useWeb3React();
	const parsedAccount = active && account && !isBech32Address(account) ? toBech32(account) : account;

	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	function handleClick(connector: AbstractConnector) {
		try {
			activate(connector);
			closeModal();
		} catch (error) {
			console.error('activation error', error);
		}
	}

	function handleDisconnectClick() {
		deactivate();
		closeModal();
	}

	return (
		<>
			<div className="connect-wallet-container" onClick={openModal}>
				<div
					className="connect-wallet-indicator"
					style={{
						backgroundColor: parsedAccount
							? getBackground(ConnectionStatus.Connected)
							: getBackground(ConnectionStatus.NotConnected),
					}}
				></div>
				<p className="connect-wallet-text">
					{parsedAccount
						? `${parsedAccount.substring(0, 6)}...${parsedAccount.substring(parsedAccount.length - 2)}`
						: getConnectWalletText(ConnectionStatus.NotConnected)}
				</p>
			</div>
			<Modal
				isOpen={isModalOpen}
				className="wallet-modal"
				overlayClassName="wallet-modal-overlay"
				onRequestClose={closeModal}
				shouldCloseOnOverlayClick
				appElement={document.getElementById('root') as HTMLElement}
			>
				<div className="wallet-modal__container">
					<img
						src={CloseButton}
						alt="Close Button"
						onClick={closeModal}
						className="wallet-modal__container__close-button"
					/>
					{isMobile || isOneWalletInstalled || isEthereumInstalled || isMathWalletInstalled ? (
						parsedAccount ? (
							<div>
								<p className="wallet-modal__container__sign-out" onClick={handleDisconnectClick}>DISCONNECT</p>
							</div>
						) : (
							Object.keys(connectorsByName)
							.filter(function (item) {
								if(item === ConnectorNames.OneWallet) {
									if(isOneWalletInstalled) {
										return true
									} else {
										return false
									}
								} else if (item === ConnectorNames.Mathwallet) {
									if(isMathWalletInstalled) {
										return true
									} else {
										return false
									}
								} else {
									if(isEthereumInstalled || isMobile) {
										return true
									} else {
										return false
									}
								}
							})
							.map(name => (
								<div
									key={name}
									className="wallet-modal__container__item-container"
									onClick={async () => await handleClick(connectorsByName[name])}
								>
									<img
										src={mapWallets[name].image}
										className="wallet-modal__container__item-image"
										alt={mapWallets[name].name}
									/>
									<p className="wallet-modal__container__item-name">{mapWallets[name].name}</p>
								</div>
							))
						)
					) : (
						<div>
							<p className="wallet-modal__container__error-message">
								It looks like you haven't installed the ONE Wallet in your browser. Please install the wallet using
								<a
									href="https://chrome.google.com/webstore/detail/harmony-one-wallet/fnnegphlobjdpkhecapkijjdkgcjhkib"
									target="_blank"
									rel="noopener noreferrer"
									style={{ color: '#FF678E' }}
									className="wallet-modal__container__error-link"
								>
									this chrome extension here
								</a>
								. You can also install chrome extensions on your Brave Browser as well. Refer to the
								<a
									href="https://support.brave.com/hc/en-us/articles/360017909112-How-can-I-add-extensions-to-Brave-"
									target="_blank"
									rel="noopener noreferrer"
									style={{ color: '#FF678E' }}
									className="wallet-modal__container__error-link"
								>
									documentation here
								</a>
							</p>
						</div>
					)}
				</div>
			</Modal>
		</>
	);
};

const getBackground = (connectedState: ConnectionStatus): string => {
	switch (connectedState) {
		case ConnectionStatus.Connected:
			return '#52E33A';
		case ConnectionStatus.NotConnected:
			return '#FF0000';
	}
};

const getConnectWalletText = (connectedState: ConnectionStatus): string => {
	switch (connectedState) {
		case ConnectionStatus.Connected:
			return 'CONNECTED';
		case ConnectionStatus.NotConnected:
			return 'NOT CONNECTED';
	}
};
