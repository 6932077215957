import { useScrollSection } from 'react-scroll-section';
import styled from 'styled-components';
import { media } from '../../utils/cssConstants';
import PirateSet from '../../img/preview/penguin-set/1. Pirate capetan.png';
import GentlemanSet from '../../img/preview/penguin-set/2. Gentleman set.png';
import CowboySet from '../../img/preview/penguin-set/3. Cowboy set.png';
import OldChineseManSet from '../../img/preview/penguin-set/4. Old Chinese set.png';
import SherlockSet from '../../img/preview/penguin-set/6. Sherloc set.png';
import WinterSet from '../../img/preview/penguin-set/7.  Winter set.png';
import HawaiinSet from '../../img/preview/penguin-set/8. Hawaiian beach set.png';
import OfficeSet from '../../img/preview/penguin-set/9. Office set.png';
import VikingSet from '../../img/preview/penguin-set/penguin_viking.jpeg';
import { useInterval } from 'usehooks-ts'
import { useState } from 'react';

interface PreviewImage {
	src: string;
	index: number;
}

const previewImages: PreviewImage[] = [
	{
		src: OldChineseManSet,
		index: 1
	},
	{
		src: PirateSet,
		index: 2
	},
	{
		src: GentlemanSet,
		index: 3
	},
	{
		src: CowboySet,
		index: 4
	},
	{
		src: SherlockSet,
		index: 5
	},
	{
		src: WinterSet,
		index: 6
	}, 
	{
		src: HawaiinSet,
		index: 7
	}, 
	{
		src: OfficeSet,
		index: 8
	},
	{
		src: VikingSet,
		index: 9
	}
]

const AboutSection = () => {
	const mintSection = useScrollSection('mint');
	const [previewImage, setPreviewImage] = useState<PreviewImage>(previewImages[0]);

	const changeImage = () => {
		if(previewImage.index === 9) {
			setPreviewImage(previewImages[0])
		} else {
			const nextImage = previewImages.find(item => item.index === (previewImage.index + 1))
			if(nextImage !== undefined) {
				setPreviewImage(nextImage)
			}
		}
	}

	useInterval(changeImage, 3000)

	return (
		<AboutSectionContainer>
			<AboutHeaderContainer>
				<CollectionName>DAT PENGUIN CLUB</CollectionName>

				<PromoVideo>
					<img src={previewImage.src} alt="Penguin Sets" style={{ maxWidth: '100%', margin: '0 auto' }} />
				</PromoVideo>
			</AboutHeaderContainer>

			<AboutContentContainer>
				<p>An upcoming Play to Earn Game on Harmony ! Club limited to only 2,500 spots. But most importantly look at these absolutely gorgeous penguins.</p>
				<MintButton onClick={mintSection.onClick}>MINT</MintButton>
			</AboutContentContainer>
		</AboutSectionContainer>
	);
};

const AboutContentContainer = styled.div`
	display: grid;
	//Can also do minmax(0, 0.8fr) here
	grid-template-columns: 0.8fr 1fr;
	margin-top: 2.5em;

	${media.mobileLandscape} {
		grid-template-columns: 1fr;
	}

	p {
		font-size: 1.5em;
		color: #fcfbfb;
	}
`;

const MintButton = styled.button`
	border: 1px solid rgba(255, 255, 255, 0.2);
	box-sizing: border-box;
	border-radius: 3em;
	padding: 0;
	margin: 0;
	align-self: center;
	color: #ff678e;
	font-size: 2em;
	font-weight: 500;
	padding: 0.5em 0em 0.5em 0em;
	margin: 0 2em;
	background: transparent;
	cursor: pointer;

	${media.mobileLandscape} {
		display: none;
	}
`;

const CollectionName = styled.div`
	//actual styling
	font-weight: normal;
	letter-spacing: -0.04em;
	font-size: 8em;
	margin: 0;
	display: flex;
	align-items: center;

	${media.largeDisplays} {
		font-size: 6.5em;
	}

	${media.tablets} {
		text-align: center;
	}

	${media.mobilePortrait} {
		font-size: 5em;
	}

	${media.extraSmall} {
		font-size: 4em;
	}
`;

const AboutHeaderContainer = styled.div`
	display: grid;
	//Can also do minmax(0, 0.8fr) here
	grid-template-columns: 0.8fr 1fr;
	gap: 1em;
	margin-top: 1.5em;

	${media.tablets} {
		grid-template-columns: 1fr;
		height: fit-content;
		margin-top: 1em;
	}
`;

const AboutSectionContainer = styled.div``;

const PromoVideo = styled.div`
	max-height: 70vh;
	display: contents;

	img {
		max-height: inherit;
	}

	${media.tablets} {
		display: block;
		position: relative;
		text-align: center;
	}

	${media.extraLargeDisplays} {
		img {
			max-height: unset;
		}
	}
`;

export default AboutSection;
